<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_package()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Edytuj kategorie pakietu</v-card-title>
          <v-card-text>

            <v-form
              ref="form" 
              v-model="valid"
              lazy-validation
            >

              <v-text-field
                v-model="package_data.name"
                label="Tytuł kategorii pakietu"
                color="primary"
                :rules="$store.state.rules.not_null"
              ></v-text-field>

            </v-form>

            <h2 class="inner_heading">Kolor wyróżniający</h2>

            <v-color-picker
              v-model="package_data.color"
              class="ma-2"
              :swatches="swatches"
              show-swatches
              mode="hexa"
            ></v-color-picker>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć kategorie pakietu?"
      @close="dialog = false;"
      @confirm="delete_package()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    valid: false,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: false,
        to: '/pakiety',
      },
      {
        text: 'Lista kategorii pakietów',
        disabled: false,
        to: '/pakiety-kategorie',
      },
      {
        text: 'Edycja kategorii pakietu',
        disabled: true,
        to: 'edytuj',
      },
    ],

    package_data: {
      name: '',
      color: ''
    },

    swatches: [
      ['#FF0000', '#AA0000', '#550000'],
      ['#FFFF00', '#AAAA00', '#555500'],
      ['#00FF00', '#00AA00', '#005500'],
      ['#00FFFF', '#00AAAA', '#005555'],
      ['#0000FF', '#0000AA', '#000055'],
    ],
  }),
  methods: {
    delete_package(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/category_package/'+this.id, data: {}, method: 'DELETE' })
      .then(resp => {
        this.$router.push('/pakiety-kategorie/');
        this.$store.commit("snackbar", {
          text: "Usunięto kategoie pakietu",
          color: "primary",
          btn_color: "white"
        });
        this.$store.commit('loader_off');
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
    },
    edit_package(){
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("name", this.package_data.name);
        formData.append("color", this.package_data.color);

        this.$axios({url: this.$store.state.api +'/category_package/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            // console.log(resp.data);

            this.$router.push('/pakiety-kategorie/');
            this.$store.commit("snackbar", {
              text: "Zaktualizowano kategorie pakietu",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/category_package/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        console.log(resp.data);
        this.package_data = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>